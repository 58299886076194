<template>
  <a
    :href="component.link"
    :class="buttonClasses"
    :target="component.newTab ? '_blank' : '_self'"
  >
    <strapi-image
      v-if="component.image"
      :image="component.image"
      :alt="component.text"
      :force-width="$props.forceImageWidth"
      :force-height="$props.forceImageHeight"
      :class="imageClasses"
    />
    <span v-else>
      {{ component.text }}
    </span>

    <slot></slot>
  </a>
</template>

<script lang="ts">
import type { StrapiButton } from '~/apollo/types/types';

export default defineComponent({
  name: 'StrapiButton',
  props: {
    component: {
      type: Object as PropType<StrapiButton>,
      required: true,
    },
    size: {
      type: String as PropType<
        'small' | 'sm' | 'medium' | 'md' | 'large' | 'lg'
      >,
      default: 'large',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    forceImageWidth: {
      type: Number,
      default: undefined,
    },
    forceImageHeight: {
      type: Number,
      default: undefined,
    },
    imageClasses: {
      type: [Array, String, Object] as PropType<
        string | Array<string> | Record<string, boolean>
      >,
      default: () => [],
    },
  },
  setup(props) {
    const buttonClasses = computed(() => {
      const classes = props.buttonClass.split(' ');

      // If we have an image set, we do not need any styles, because the image is the
      // visible element to click on.
      if (props.component.image) {
        return classes;
      }

      classes.push('btn');
      classes.push('btn-lg');

      if (['small', 'sm'].some((smallSizes) => smallSizes === props.size)) {
        classes.push('btn-sm');
      } else if (
        ['large', 'lg'].some((smallSizes) => smallSizes === props.size)
      ) {
        classes.push('btn-lg');
      }

      if (props.component.type != null) {
        classes.push(`btn-${props.component.type}`);
      }

      return classes;
    });

    return { buttonClasses };
  },
});
</script>
